
  /*#region --ENTRY/INPUT--*/
    /*ENTRY/INPUT-DEFECTO*/
  .input input{
    background: white !important;
    font-family: var(--fuente)!important;
    /*font-size: 1.1rem!important;*/
    color: var(--color1) !important;
    font-weight: 500;
  }

   /*ENTRY/INPUT-DESHABILITADO*/
  .input input:read-only, input:disabled{
    -webkit-text-fill-color: var(--disableFont) !important;
    color: var(--disableFont) !important;
    background-color: var(--disableFont); /* modificado 231005 white !important;*/
    font-weight: 500;
  }
  

  [role="combobox"]:disabled {
    background-color: white !important;
  }
  /*#endregion --ENTRY/INPUT--*/

  .Mui-focused{
    border-color: white !important;
  }