.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .modalseccionales {
    position: fixed;
    width: 60rem;
    height: 50rem;
    left: calc(50% - 30rem);
    top: calc(50% - 25rem);
    background-color: #d1dfe9; /*#e7effd;*/
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
  }

  .modal {
    position: fixed;
    top: 5vh;
    left: 5%;
    width: 50%;
    background-color: #d1dfe9; /*#e7effd;*/
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
  }

	.content {
		height: 100%;
	}
  


  /*MEDIA QUERY*/
  @media (min-width: 481) {/*TABLETS/IPADS*/
    .modal{
      zoom: 75%;
    }
  }
  @media (min-width: 769px) {/*LAPTOPS*/
    .modal {
      width: 60rem;
      height: 58rem;
      left: calc(50% - 30rem);
      top: calc(50% - 29rem);
    }
  }
  @media (min-width: 1025px) {/*DESKTOP*/
    .modal {
      width: 70rem;
      height: 54rem;
      left: calc(50% - 35rem);
      top: calc(50% - 27rem);
    }
  }
  @media (min-width: 1201px) {/*MONITORES GRANDES, TVs*/
    .modal {
      width: 70rem;
      height: 55rem;
      left: calc(50% - 35rem);
      top: calc(50% - 27.5rem);
    }
  }

  @media (min-width: 1901px) {/*MONITORES GRANDES, TVs*/
    .modal {
      width: 70rem;
      height: 55rem;
      left: calc(50% - 35rem);
      top: calc(50% - 25rem);
    }
  }

  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.modalCabecera{
  background-color: var(--color1);
  color:white;
  border-radius: 15px 15px 0px 0px;
}

/*TAB EN MODAL*/
.modal [role="tab"]{
  font-size: large !important;
}

/*INPUT/SELECT DEL MODAL*/
/* .modal input,[aria-expanded]{
  padding: 1rem 1rem 0.2rem 1rem !important;
} */

.modal fieldset{
  /*border-color: var(--gris2) !important;*/
}

