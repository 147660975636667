
.headerClass{
    color: white;
    background-color: rgb(85 85 85 / 90%);
}

td, th {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

selectRow{
  backgroundColor: var(--amarillo2);
  color: black;
  fontWeight: bold;
}