.div {
  width: 100%;
  /*margin-top: 1%;*/
  align-items: center;
  align-content: center;
}

.renglon {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1.3%;
  display: flex;
  flex-direction: row;
  max-height: 3rem;
}

.input {
  width: 50%;
  /* margin-right: 1%; */
  padding: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.input25 {
  width: 25%;
  /* margin-right: 1%; */
  padding: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.input100 {
  width: 100%;
  /* margin-right: 1%; */
  padding: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
}