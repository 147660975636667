.div {
  width: 100%;
  /*margin-top: 1%;*/
  align-items: center;
  align-content: center;;
}

.divResolverSolicitud {
  width: 100%;
  margin-bottom: 8%;
  align-items: center;
  align-content: center;;
}

.renglon {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1.3%;
  display: flex;
  flex-direction: row;
  max-height: 3rem;
}

.renglonActividad {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  max-height: 3rem;
}

.renglonDDJJ {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  height: 5%;
}

.alert {
  width: 100%;
  /*margin-top: 0.3%;*/
  /* display: flex; */
  /* flex-direction: row; */
}

.input {
  width: 50%;
  /* margin-right: 1%; */
  padding: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.input13 {
  width: 13%;
  /* margin-right: 1%; */
  padding: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.input20 {
  width: 20%;
  /* margin-right: 1%; */
  padding: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.input25 {
  width: 24.5%;
  /* margin-right: 1%; */
  padding: -5;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.input33 {
  width: 33%;
  /* margin-right: 1%; */
  padding: -5;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.input50{
  width: 50%;
  /* margin-right: 1%; */
  padding: -5;
  margin-left: 0.5%;
  margin-right: 0.5%;
  display: flex; 
}

.input75 {
  width: 75%;
  /* margin-right: 1%; */
  padding: -5;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.input100 {
  width: 100%;
  padding: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.seccion {
  margin-top: 1%;
  width: 50%;
  margin-right: 5%;
}

.boton {
  width: 30%;
  margin: 2%;
}

.botonResolverSolicitud {
  width: 30%;
  margin: 0.5%;
}
.botones {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1%;
  margin-top: 1%;
  width: 96%;
  text-align: center;
}

.botonesResolverSolicitud {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 1%;
  width: 96%;
  text-align: center;
}

.titulo {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 0.5%;
}


.labelEsRural {
  color: green;
}

.MuiFormHelperText-root {
  color: red;
}

.smallTable {
  height: 15%;
}

.subTituloVentana {
  display: flex;
  flex-direction: row;
  justify-content: center;  
}

/*Seccion del pie de pagina donde se encuentran los botones */
.footer{
  margin-top: 3rem !important;
  display: flex;
  justify-content: space-around;
}