@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  /*ESCALA DE AZULES*/
  --color1: #1E5980; /*#186090;*/
  --color2: #3595D2;
  --color3: #8bb0c8;

  --color3-40: #8cb0c766; /*#d1dfe9*/
  --color3-80: #8cb0c7cc;
 
  /*82C3ED50 para tablas*/
  --color4: rgb(13 110 253 / 50%); /*focus*/

  /*ESACALA DE GRISES*/
  --gris1: #555555;
  --gris2: #C2C2C2;
  --gris3: #727272;
  --disableFont: #555555;/*#8c8c8c;*/
  --disableBack: #c2c2c2;

/*ESACALA DE AMARILLOS*/
--amarillo1: #E3A64F;
--amarillo2: #EEC85E;
--amarillo3: #FADD8E;

  --blanco: #FFF;

  /*FUENTE*/
  /*--fuente: "Light";*/
  --fuente: 'Poppins';
}

body {
  margin: 0;
  
  font-family: var(--fuente), sans-serif;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
}

code {
    font-family: var(--fuente), sans-serif;
    /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;*/
}

#overlay-root,
body:has(#overlay-root:not(:empty)) > .MuiPopover-root > .MuiPaper-root {
	zoom: 75%;
}

main{
    margin: 0;
   
    font-family: var(--fuente), sans-serif;
     /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;*/
    display: flex;
    width: 100%;
  
}


.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.bg-image {
      opacity: 0.0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: auto;
      position: fixed;
}

#menu- >[tabindex="-1"]{
 /* margin: -1rem 0rem 0rem -8rem;*/
   margin: -4rem 0rem 0rem -21rem;
}

.underline{
  text-decoration: underline !important;
}


.MuiAutocomplete-inputRoot{
  max-height: 40px !important;
  padding: 0 !important;
}