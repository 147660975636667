.container{
    display: grid;
    grid-template-columns: auto auto auto; /*15rem 15rem 15rem;*/
    grid-template-rows: auto auto auto auto;
    gap: 20px;
    margin: 1rem;
    }
    
    
    .item0{
        grid-row: span 1;
        grid-column: span 3;
        width: 6rem;
    }
    .item1{
        grid-row: span 1;
        grid-column: span 1;
    }
    
    .item2{
        grid-row: span 1;
        grid-column: span 2;
    }
    
    .item3{
        grid-row: span 1;
        grid-column: span 1;
        /*max-width: 15rem;*/
        padding: 0;
    }
    
    .item4{
        grid-row: span 1;
        grid-column: span 2;
    }
    
    .item5{
        grid-row: span 1;
        grid-column: span 1;
    }

    .item6{
        grid-row: span 1;
        grid-column: span 2;
    }
    .item7{
        grid-row: span 1;
        grid-column: span 1;
    }
    .item8{
        grid-row: span 1;
        grid-column: span 2;
    }
    .item9{
        grid-row: span 1;
        grid-column: span 3;
    }